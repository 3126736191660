<template>
  <section class="course-cateory-section programs-offer">
    <div class="container">
      <div class="category-cntr">
        <breadCrumb :currentScreen="screen" />
        <div class="row">
          <div class="col-md-12">
            <div class="generic-wizard-heading">Select Your Group</div>
            <div class="generic-layout-cntr" v-if="programCategories.length && groups.length">
              <div class="board-box" v-for="(category, index) in this.groups" :key="index" @click="goto(category)">
                <div class="course-icon">
                  <img height="51" :src="category.Group.CourseGroupIcon" />
                </div>
                <div>
                  <div class="board-box-head">{{ category.Group.CourseGroupDisplayName }}</div>
                </div>
              </div>
            </div>
            <div v-else class="text-center my-5">No group found.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button @click="goBack()" class="btn secondary-btn">Go back</button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { appConstants } from "../../../common/constants";
import { programCategories as programCategoriesStoreKeys } from "../../../store/keys";
import breadCrumb from "./breadCrumb";
import cryptojs from "../../../plugins/crypto";

export default {
  components: {
    breadCrumb,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      screen: appConstants.enums.programCategoryFlowKey.group,
    };
  },
  async serverPrefetch() {},
  computed: {
    ...mapGetters(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.getters),
    groups() {
      try {
        const filteredRecords = this.filteredRecords(this.$decryptedRouteParams.categoryId, this.$decryptedRouteParams.programId != this.defaultRouteValue ? this.$decryptedRouteParams.programId : null, this.$decryptedRouteParams.boardId != this.defaultRouteValue ? this.$decryptedRouteParams.boardId : null, null);
        const uniqueGroups = [
          ...new Set(
            filteredRecords
              .filter((obj) => {
                if (obj.Group) {
                  return obj;
                }
              })
              .map((obj) => obj.Group.CourseGroupId)
          ),
        ].map((id) => {
          return filteredRecords.find((obj) => obj.Group && obj.Group.CourseGroupId === id);
        });
        return uniqueGroups;
      } catch (error) {
        return [];
      }
    },
  },
  methods: {
    ...mapActions(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.actions),
    getProgramCategories() {
      this.get_ProgramCategories({
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
      });
    },
    goto(category) {
      const categoryFlow = this.programCategories.find((el) => el.ProgramCategory.ProgramCategoryId == this.$decryptedRouteParams.categoryId).ProgramCategoryFlow;
      const currentIndex = categoryFlow.findIndex((el) => el.NodeKey === appConstants.enums.programCategoryFlowKey.group);
      const nextRoute = categoryFlow[currentIndex + 1];
      this.$router.push({
        name: this.getRouteName(nextRoute.NodeKey),
        params: {
          categoryId: cryptojs.UTF.encrypt(category.ProgramCategory.ProgramCategoryId),
          programId: this.$route.params.programId ? this.$route.params.programId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          boardId: this.$route.params.boardId ? this.$route.params.boardId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          groupId: cryptojs.UTF.encrypt(category.Group.CourseGroupId),
        },
      });
    },
    goBack() {
      const categoryFlow = this.programCategories.find((el) => el.ProgramCategory.ProgramCategoryId == this.$decryptedRouteParams.categoryId).ProgramCategoryFlow;
      const currentIndex = categoryFlow.findIndex((el) => el.NodeKey === appConstants.enums.programCategoryFlowKey.group);
      let condition = currentIndex > 0 ? categoryFlow[currentIndex - 1].NodeKey : currentIndex == 0 ? "category" : categoryFlow[currentIndex].NodeKey;
      var name = this.getRouteName(condition);
      this.$router.push({
        name: name ? name : "category",
        params: {
          categoryId: this.$route.params.categoryId,
          boardId: this.$route.params.boardId ? this.$route.params.boardId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          programId: this.$route.params.programId ? this.$route.params.programId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          groupId: this.$route.params.groupId ? this.$route.params.groupId : cryptojs.UTF.encrypt(this.defaultRouteValue),
        },
      });
    },
  },
};
</script>

<style></style>

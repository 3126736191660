<template>
  <section class="course-cateory-section programs-offer">
    <div class="container">
      <div class="category-cntr">
        <breadCrumb :currentScreen="screen" />
        <div class="row">
          <div class="col-md-12">
            <div class="generic-wizard-heading">Select Your Program</div>
            <div class="generic-layout-cntr" v-if="programCategories.length && programs.length">
              <div class="board-box" v-for="(category, index) in this.programs" :key="index" @click="goto(category)">
                <div>
                  <div class="board-box-head">{{ category.AcademicProgram.AcademicProgramName }}</div>
                </div>
              </div>
            </div>
            <div v-else class="text-center my-5">No program found.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button @click="goBack()" class="btn secondary-btn">Go back</button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { appConstants } from "../../../common/constants";
import { programCategories as programCategoriesStoreKeys } from "../../../store/keys";
import breadCrumb from "./breadCrumb";
import cryptojs from "../../../plugins/crypto";
export default {
  components: {
    breadCrumb,
  },
  data() {
    return {
      screen: appConstants.enums.programCategoryFlowKey.program,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getProgramCategories();
  },
  computed: {
    ...mapGetters(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.getters),
    programs() {
      try {
        const filteredRecords = this.filteredRecords(this.$decryptedRouteParams.categoryId, null, this.$decryptedRouteParams.boardId != this.defaultRouteValue ? this.$decryptedRouteParams.boardId : null, this.$decryptedRouteParams.groupId != this.defaultRouteValue ? this.$decryptedRouteParams.groupId : null);
        const uniquePrograms = [
          ...new Set(
            filteredRecords
              .filter((obj) => {
                if (obj.AcademicProgram) {
                  return obj;
                }
              })
              .map((obj) => obj.AcademicProgram.AcademicProgramId)
          ),
        ].map((id) => {
          return filteredRecords.find((obj) => obj.AcademicProgram && obj.AcademicProgram.AcademicProgramId === id);
        });
        return uniquePrograms;
      } catch (error) {
        return [];
      }
    },
  },
  methods: {
    ...mapActions(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.actions),
    getProgramCategories() {
      this.get_ProgramCategories({
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
      });
    },
    goto(category) {
      const categoryFlow = this.programCategories.find((el) => el.ProgramCategory.ProgramCategoryId == this.$decryptedRouteParams.categoryId).ProgramCategoryFlow;
      const currentIndex = categoryFlow.findIndex((el) => el.NodeKey === appConstants.enums.programCategoryFlowKey.program);
      const nextRoute = categoryFlow[currentIndex + 1];
      this.$router.push({
        name: this.getRouteName(nextRoute.NodeKey),
        params: {
          categoryId: cryptojs.UTF.encrypt(category.ProgramCategory.ProgramCategoryId),
          boardId: this.$route.params.boardId ? this.$route.params.boardId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          groupId: this.$route.params.groupId ? this.$route.params.groupId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          programId: cryptojs.UTF.encrypt(category.AcademicProgram.AcademicProgramId),
        },
      });
    },
    goBack() {
      const categoryFlow = this.programCategories.find((el) => el.ProgramCategory.ProgramCategoryId == this.$decryptedRouteParams.categoryId).ProgramCategoryFlow;
      const currentIndex = categoryFlow.findIndex((el) => el.NodeKey === appConstants.enums.programCategoryFlowKey.program);
      let condition = currentIndex > 0 ? categoryFlow[currentIndex - 1].NodeKey : currentIndex == 0 ? "category" : categoryFlow[currentIndex].NodeKey;
      var name = this.getRouteName(condition);
      this.$router.push({
        name: name ? name : "category",
        params: {
          categoryId: this.$route.params.categoryId,
          boardId: this.$route.params.boardId ? this.$route.params.boardId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          programId: this.$route.params.programId ? this.$route.params.programId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          groupId: this.$route.params.groupId ? this.$route.params.groupId : cryptojs.UTF.encrypt(this.defaultRouteValue),
        },
      });
    },
  },
};
</script>

<style></style>

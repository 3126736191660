<template>
  <div v-if="programCategories.length && breadCrumbs.length">
    <div class="breadcrumb-wrapper col-md-12 d-flex justify-content-center mb-3">
      <div class="category-breadcrumb" v-for="(val, index) in this.breadCrumbs" :key="index">
        <div class="name" @click="val.NodeKey === currentScreen ? null : goBack(val)" :class="val.NodeKey === currentScreen ? 'disabled' : ''">{{ val.selectedValue.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { appConstants } from "../../../common/constants";
import { programCategories as programCategoriesStoreKeys } from "../../../store/keys";
import cryptojs from "../../../plugins/crypto";

export default {
  props: {
    currentScreen: "",
  },
  mounted() {
    this.getProgramCategories();
  },
  async serverPrefetch() {
    return this.getProgramCategories();
  },
  computed: {
    ...mapGetters(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.getters),
    breadCrumbs() {
      if (this.programCategories.length) {
        try {
          const categoryFlow = this.programCategories.find((el) => el.ProgramCategory.ProgramCategoryId == this.$decryptedRouteParams.categoryId).ProgramCategoryFlow;
          const currentScreenIndex = categoryFlow.findIndex((el) => el.NodeKey === this.currentScreen);
          const breadCrumb = categoryFlow.slice(0, currentScreenIndex + 1);
          const array = breadCrumb.map((el) => {
            return {
              ...el,
              backRoute: {
                name: "",
                params: {},
              },
              selectedValue: {
                name: el.NodeKey,
              },
            };
          });
          array.forEach((el, index, arr) => {
            el.backRoute = {
              name: this.getRouteName(el.NodeKey),
              params: {
                ...(el.NodeKey === appConstants.enums.programCategoryFlowKey.program && { programId: this.$route.params.programId, ...(index > 0 && { ...arr[index - 1].backRoute.params }) }),
                ...(el.NodeKey === appConstants.enums.programCategoryFlowKey.board && { boardId: this.$route.params.boardId, ...(index > 0 && { ...arr[index - 1].backRoute.params }) }),
                ...(el.NodeKey === appConstants.enums.programCategoryFlowKey.group && { groupId: this.$route.params.groupId, ...(index > 0 && { ...arr[index - 1].backRoute.params }) }),
              },
            };
            el.selectedValue = {
              ...(el.NodeKey === appConstants.enums.programCategoryFlowKey.program && { name: this.currentScreen === appConstants.enums.programCategoryFlowKey.program ? el.NodeKey : this.filteredRecords(this.$decryptedRouteParams.categoryId, this.$decryptedRouteParams.programId != this.defaultRouteValue ? this.$decryptedRouteParams.programId : null, this.$decryptedRouteParams.boardId != this.defaultRouteValue ? this.$decryptedRouteParams.boardId : null, this.$decryptedRouteParams.groupId != this.defaultRouteValue ? this.$decryptedRouteParams.groupId : null)[0].AcademicProgram.AcademicProgramName }),
              ...(el.NodeKey === appConstants.enums.programCategoryFlowKey.board && { name: this.currentScreen === appConstants.enums.programCategoryFlowKey.board ? el.NodeKey : this.filteredRecords(this.$decryptedRouteParams.categoryId, this.$decryptedRouteParams.programId != this.defaultRouteValue ? this.$decryptedRouteParams.programId : null, this.$decryptedRouteParams.boardId != this.defaultRouteValue ? this.$decryptedRouteParams.boardId : null, this.$decryptedRouteParams.groupId != this.defaultRouteValue ? this.$decryptedRouteParams.groupId : null)[0].Board.BoardName }),
              ...(el.NodeKey === appConstants.enums.programCategoryFlowKey.group && { name: this.currentScreen === appConstants.enums.programCategoryFlowKey.group ? el.NodeKey : this.filteredRecords(this.$decryptedRouteParams.categoryId, this.$decryptedRouteParams.programId != this.defaultRouteValue ? this.$decryptedRouteParams.programId : null, this.$decryptedRouteParams.boardId != this.defaultRouteValue ? this.$decryptedRouteParams.boardId : null, this.$decryptedRouteParams.groupId != this.defaultRouteValue ? this.$decryptedRouteParams.groupId : null)[0].Group.CourseGroupDisplayName }),
              ...(el.NodeKey === appConstants.enums.programCategoryFlowKey.course && { name: this.currentScreen === appConstants.enums.programCategoryFlowKey.course ? el.NodeKey : this.filteredRecords(this.$decryptedRouteParams.categoryId, this.$decryptedRouteParams.programId != this.defaultRouteValue ? this.$decryptedRouteParams.programId : null, this.$decryptedRouteParams.boardId != this.defaultRouteValue ? this.$decryptedRouteParams.boardId : null, this.$decryptedRouteParams.groupId != this.defaultRouteValue ? this.$decryptedRouteParams.groupId : null)[0].Group.CourseGroupDisplayName }),
            };
          });
          array.unshift({
            backRoute: {
              name: "category",
              params: {},
            },
            selectedValue: {
              name: this.programCategories.find((el) => el.ProgramCategory.ProgramCategoryId == this.$decryptedRouteParams.categoryId).ProgramCategory.Category,
            },
          });
          return array;
        } catch (error) {
          // this.$router.push({ name: "landingPageNotFound" });
          return [];
        }
      }
    },
  },
  methods: {
    ...mapActions(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.actions),
    goBack(arg) {
      this.$router.push({
        name: arg.backRoute.name,
        params: {
          categoryId: this.$route.params.categoryId ? this.$route.params.categoryId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          boardId: arg.backRoute.params.boardId ? arg.backRoute.params.boardId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          groupId: arg.backRoute.params.groupId ? arg.backRoute.params.groupId : cryptojs.UTF.encrypt(this.defaultRouteValue),
          programId: arg.backRoute.params.programId ? arg.backRoute.params.programId : cryptojs.UTF.encrypt(this.defaultRouteValue),
        },
      });
    },
    getProgramCategories() {
      this.get_ProgramCategories({
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
      });
    },
  },
};
</script>

<style></style>
